import { SET_DATA_TEXTS } from './types';

const initialState = {
    texts: null
};

export const textsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_TEXTS:
            const texts = {};

            for (const item of action.payload) {
                texts[item.type] = item;
            }

            return { ...state, texts };
        default:
            return state;
    }
};

export const selectTexts = (state) => state.texts;
