import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { appReducer } from './appReducer';
import { questionnaireReducer } from 'redux/questionnaireReducer';
import { dataReducer } from 'redux/dataReducer';
import { respondentReducer } from 'redux/respondentReducer';
import { statisticReducer } from 'redux/statisticReducer';
import { editingReducer } from 'redux/editingReducer';
import { textsReducer } from 'redux/textsReducer';
import { recommendationsFilterReducer } from 'redux/recommendationsFilterReducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    data: dataReducer,
    questionnaire: questionnaireReducer,
    respondent: respondentReducer,
    statistic: statisticReducer,
    editing: editingReducer,
    texts: textsReducer,
    recommendationsFilter: recommendationsFilterReducer
});
