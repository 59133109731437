import fetch from 'redux/fetch';
import { finishLoading, showAlert, startLoading } from 'redux/appReducer';
import { UPDATE_USER, SET_USER } from './types';

const initialState = {
    user: null
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case SET_USER:
            const { user } = action.payload;
            return { ...state, user };
        default:
            return state;
    }
};

export const updateUser = (data) => {
    return async (dispatch, getState) => {
        const { user } = getState().auth;

        try {
            dispatch(startLoading());

            const json = await fetch(
                `/api/specialist/update`,
                {
                    method: 'PUT',
                    body: JSON.stringify({ ...data, id: user._id }),
                    headers: { 'Content-Type': 'application/json' }
                },
                getState,
                dispatch
            );

            dispatch({ type: UPDATE_USER, payload: json });
            dispatch(showAlert({ type: 'success', text: 'Изменения внесены успешно' }));
        } catch (e) {
            dispatch(showAlert({ type: 'error', text: e.message }));
        } finally {
            dispatch(finishLoading());
        }
    };
};

export const selectUser = (state) => state.auth;
