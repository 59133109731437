import axios from 'axios';
import { toast } from 'react-toastify';

import { showAlert, finishLoading, startLoading } from 'redux/appReducer';
import {
    CHANGE_RESPONDENT_MARK,
    CHANGE_RESPONDENT_SPECIALIST,
    CLEAR_RESPONDENTS_FILTERS,
    GET_MORE_RESPONDENTS,
    GET_RESPONDENT,
    GET_RESPONDENTS_COUNT,
    SET_DATES_FILTERS,
    SET_RESPONDENTS_FILTERS,
    SET_SEARCH_RESPONDENT
} from './types';
import { queryClient } from 'index';

export const initFilters = {
    region: {
        value: '',
        label: ''
    },
    specialist: {
        value: '',
        label: ''
    },
    dateStart: '',
    dateEnd: '',
    marks: ['mark1', 'mark2', 'mark3', 'other']
    // mark: {
    //     value: '',
    //     label: ''
    // },
};

const initialState = {
    respondent: null,
    respondents: [],
    respondentsCount: 0,
    search: {
        type: 'number',
        value: ''
    },
    filters: initFilters
};

export const respondentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESPONDENT:
            return { ...state, respondent: action.payload };
        case CHANGE_RESPONDENT_SPECIALIST: {
            const respondent = { ...state.respondent };
            respondent.specialist = action.payload.specialist;

            return { ...state, respondent };
        }
        case CHANGE_RESPONDENT_MARK: {
            const respondent = { ...state.respondent };
            respondent.marks = action.payload.marks;

            return { ...state, respondent };
        }
        case SET_SEARCH_RESPONDENT: {
            const { type, value } = action.payload;
            return { ...state, search: { type, value } };
        }
        case SET_RESPONDENTS_FILTERS: {
            const filters = { ...state.filters };
            const { field, value } = action.payload;
            filters[field] = value;

            return { ...state, filters };
        }
        case SET_DATES_FILTERS: {
            const filters = { ...state.filters };
            const { dateStart, dateEnd } = action.payload;
            filters.dateStart = dateStart;
            filters.dateEnd = dateEnd;

            return { ...state, filters };
        }
        case CLEAR_RESPONDENTS_FILTERS: {
            return {
                ...state,
                filters: initFilters
            };
        }
        case GET_MORE_RESPONDENTS:
            const respondents = [...state.respondents, ...action.payload];
            return { ...state, respondents };
        case GET_RESPONDENTS_COUNT:
            return { ...state, respondentsCount: action.payload.respondentsCount };
        default:
            return state;
    }
};

export const sendRespondentResult = (data) => async (dispatch) => {
    try {
        dispatch(startLoading());

        const result = await axios.post('respondents/save', data);

        dispatch({ type: GET_RESPONDENT, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchRespondent = (id) => async (dispatch) => {
    try {
        dispatch(startLoading());

        const result = await axios.get(`respondents/${id}`);

        dispatch({ type: GET_RESPONDENT, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const updateRespondent = (id, data) => async (dispatch) => {
    try {
        dispatch(startLoading());

        const result = await axios.put(`respondents/${id}`, data);

        dispatch({ type: GET_RESPONDENT, payload: result.data });
        toast.success('Информация о респонденте обновлена');
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const changeRespondentSpecialist =
    (id, currentSpecialistId, newSpecialistId) => async (dispatch) => {
        try {
            dispatch(startLoading());

            const result = await axios.put(`respondents/change-specialist/${id}`, {
                currentSpecialistId,
                newSpecialistId
            });

            dispatch({ type: CHANGE_RESPONDENT_SPECIALIST, payload: result.data });
        } catch (e) {
            const text = e.response?.data?.message || 'Что-то пошло не так';
            dispatch(showAlert({ type: 'error', text }));
        } finally {
            dispatch(finishLoading());
        }
    };

export const changeRespondentMark = (id, marks) => async (dispatch) => {
    try {
        const result = await axios.put(`respondents/change-mark/${id}`, {
            marks
        });

        dispatch({ type: CHANGE_RESPONDENT_MARK, payload: result.data });
        await queryClient.refetchQueries(['respondents']);
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    }
};

export const fetchRespondentByToken = () => async (dispatch) => {
    try {
        dispatch(startLoading());

        const result = await axios.get('respondents/by-token');

        dispatch({ type: GET_RESPONDENT, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
        setTimeout(() => {
            window.location.reload();
        }, 2500);
    } finally {
        dispatch(finishLoading());
    }
};

export const setSearch = (type, value) => (dispatch) => {
    dispatch({ type: SET_SEARCH_RESPONDENT, payload: { type, value } });
};

export const clearSearch = () => async (dispatch) => {
    dispatch({ type: SET_SEARCH_RESPONDENT, payload: { type: 'number', value: '' } });
};

export const setRespondentsFilters = (field, value) => (dispatch) => {
    dispatch({ type: SET_RESPONDENTS_FILTERS, payload: { field, value } });
};

export const setDatesFilters =
    ({ dateStart, dateEnd }) =>
    (dispatch) => {
        dispatch({ type: SET_DATES_FILTERS, payload: { dateStart, dateEnd } });
    };

export const clearRespondentsFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_RESPONDENTS_FILTERS });
};

// export const fetchRespondentsCount = () => async (dispatch) => {
//     try {
//         dispatch(startLoading());
//
//         const result = await axios.get('respondents/count');
//
//         dispatch({ type: GET_RESPONDENTS_COUNT, payload: result.data });
//     } catch (e) {
//         const text = e.response?.data?.message || 'Что-то пошло не так';
//         dispatch(showAlert({ type: 'error', text }));
//     } finally {
//         dispatch(finishLoading());
//     }
// };

export const deleteRespondent = (id) => async (dispatch) => {
    try {
        dispatch(startLoading());

        await axios.delete(`respondents/${id}`);

        window.location = '/';
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const selectRespondent = (state) => state.respondent;
