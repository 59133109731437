import axios from 'axios';

import { finishLoading, startLoading, showAlert } from 'redux/appReducer';
import {
    SET_DATA_FACTORS,
    SET_DATA_FACTORS_FOR_RECOMS,
    SET_DATA_QUESTIONS,
    SET_DATA_QUESTIONS_FOR_RECOMS
} from './types';

const initialState = {
    factors: [],
    factorsForRecoms: [],
    questions: [],
    questionsForRecoms: []
};

export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_FACTORS:
            return { ...state, factors: action.payload };
        case SET_DATA_FACTORS_FOR_RECOMS:
            return { ...state, factorsForRecoms: action.payload };
        case SET_DATA_QUESTIONS:
            return { ...state, questions: action.payload };
        case SET_DATA_QUESTIONS_FOR_RECOMS:
            return { ...state, questionsForRecoms: action.payload };
        default:
            return state;
    }
};

export const fetchFactors = () => async (dispatch) => {
    try {
        dispatch(startLoading());

        const response = await axios.get('factors');

        dispatch({ type: SET_DATA_FACTORS, payload: response.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

// export const fetchFactorsForRecoms = () => async (dispatch) => {
//     try {
//         dispatch(startLoading());
//
//         const response = await axios.get('factors/for-recoms');
//
//         dispatch({ type: SET_DATA_FACTORS_FOR_RECOMS, payload: response.data });
//     } catch (e) {
//         const text = e.response?.data?.message || 'Что-то пошло не так';
//         dispatch(showAlert({ type: 'error', text }));
//     } finally {
//         dispatch(finishLoading());
//     }
// };

export const fetchQuestions = () => async (dispatch) => {
    try {
        dispatch(startLoading());

        const response = await axios.get('questionnaire/questions');

        dispatch({ type: SET_DATA_QUESTIONS, payload: response.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

// export const fetchQuestionsForRecoms = () => async (dispatch) => {
//     try {
//         dispatch(startLoading());
//
//         const response = await axios.get('questionnaire/for-recoms');
//
//         dispatch({ type: SET_DATA_QUESTIONS_FOR_RECOMS, payload: response.data });
//     } catch (e) {
//         const text = e.response?.data?.message || 'Что-то пошло не так';
//         dispatch(showAlert({ type: 'error', text }));
//     } finally {
//         dispatch(finishLoading());
//     }
// };

export const selectData = (state) => state.data;
