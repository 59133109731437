import {
    SET_CHOSEN_ANSWERS,
    SET_FACTORS_IMPORTANCE,
    SET_FACTORS_QUESTIONS,
    SET_FILLING_START_TIME,
    SET_LOCATION,
    SET_QUESTIONNAIRE_FIELD,
    SET_QUESTIONNAIRE_FORM
} from './types';

const initialState = {
    form: {
        age: '',
        youngestChild: '',
        previousAbortions: '',
        siblings: ''
    },
    location: {
        id: null,
        value: '',
        region: '',
        city: ''
    },
    questionnaire: {
        residenceType: [],
        education: [],
        employment: [],
        professionalArea: [],
        marriage: [],
        children: [],
        family: [],
        numberOfChildren: [],
        conditionsForChildren: [],
        moreChildren: [],
        force: [],
        relativesHelp: [],
        stateSupport: [],
        parentsFamily: []
    },
    chosenAnswers: [],
    fillingStartTime: null
};

export const questionnaireReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUESTIONNAIRE_FIELD:
            const questionnaire = {
                ...state.questionnaire,
                [action.payload.field]: action.payload.value
            };

            return { ...state, questionnaire };
        case SET_QUESTIONNAIRE_FORM:
            const form = {
                ...state.form,
                [action.payload.name]: action.payload.value
            };

            return { ...state, form };
        case SET_LOCATION:
            return { ...state, location: action.payload };
        case SET_CHOSEN_ANSWERS:
            const chosenAnswers = [...state.chosenAnswers];
            const findIndex = chosenAnswers.findIndex(
                ({ factor }) => factor === action.payload.factor
            );

            if (findIndex === -1) {
                chosenAnswers.push(action.payload);
            } else {
                chosenAnswers[findIndex] = action.payload;
            }

            return { ...state, chosenAnswers };
        case SET_FACTORS_IMPORTANCE:
            const importanceAnswers = action.payload;
            const addImportanceAnswers = state.chosenFactors.map((item, i) => ({
                ...item,
                importance: importanceAnswers[i]
            }));

            return { ...state, chosenFactors: addImportanceAnswers };
        case SET_FACTORS_QUESTIONS:
            return { ...state, chosenFactors: action.payload };
        case SET_FILLING_START_TIME:
            return { ...state, fillingStartTime: Date.now() };
        default:
            return state;
    }
};

export const setChosenAnswers = (factors) => (dispatch) => {
    dispatch({
        type: SET_CHOSEN_ANSWERS,
        payload: factors
    });
};

export const setFactorsImportance = (answers) => (dispatch) => {
    dispatch({
        type: SET_FACTORS_IMPORTANCE,
        payload: answers
    });
};

export const setFillingStartTime = () => (dispatch) => {
    dispatch({
        type: SET_FILLING_START_TIME
    });
};

export const selectQuestionnaire = (state) => state.questionnaire;
