import {
    SET_DATA_EDITING_FACTOR,
    SET_DATA_EDITING_FACTOR_ANSWER,
    SET_DATA_EDITING_QUESTION,
    SET_DATA_EDITING_QUESTION_ANSWER,
    SET_DATA_EDITING_RECOMMENDATION,
    SET_EDITING_FACTOR_ANSWER_DELETE,
    SET_EDITING_QUESTION_ANSWER_DELETE,
    SET_MARKED_RECOMS,
    SET_SUPPORT_TYPE
} from './types';

const initialState = {
    question: null,
    questionAnswer: null,
    factor: null,
    factorAnswer: null,
    recommendation: null,
    markedRecoms: {
        filter1: [],
        filter2: [],
        filter3: [],
        filter4: []
    },
    supportType: null
};

export const editingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_EDITING_QUESTION:
            return { ...state, question: action.payload };
        case SET_DATA_EDITING_QUESTION_ANSWER:
            return { ...state, questionAnswer: action.payload };
        case SET_EDITING_QUESTION_ANSWER_DELETE: {
            const answers = state.question.answers.filter(({ _id }) => _id !== action.payload);
            return { ...state, question: { ...state.question, answers } };
        }
        case SET_DATA_EDITING_FACTOR:
            return { ...state, factor: action.payload };
        case SET_DATA_EDITING_FACTOR_ANSWER:
            return { ...state, factorAnswer: action.payload };
        case SET_EDITING_FACTOR_ANSWER_DELETE: {
            const answers = state.factor.answers.filter(({ _id }) => _id !== action.payload);
            return { ...state, factor: { ...state.factor, answers } };
        }
        case SET_DATA_EDITING_RECOMMENDATION:
            return { ...state, recommendation: action.payload };
        case SET_MARKED_RECOMS:
            return { ...state, markedRecoms: action.payload };
        case SET_SUPPORT_TYPE:
            return { ...state, supportType: action.payload };
        default:
            return state;
    }
};

export const selectEditing = (state) => state.editing;
