import {
    RECOMMENDATIONS_FILTER_CLEAR,
    RECOMMENDATIONS_FILTER_SET_DATE,
    RECOMMENDATIONS_FILTER_SET_MARK_STATUS,
    RECOMMENDATIONS_FILTER_SET_MARK_STATUS_TYPE,
    RECOMMENDATIONS_FILTER_SET_REGION,
    RECOMMENDATIONS_FILTER_SORT_TYPE
} from './types';

const initialState = {
    region: {
        value: '',
        label: ''
    },
    dateStart: '',
    dateEnd: '',
    markStatus: [],
    markStatusType: 'any',
    sortType: 'region'
};

export const recommendationsFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECOMMENDATIONS_FILTER_SET_REGION:
            return { ...state, region: action.payload };
        case RECOMMENDATIONS_FILTER_SET_DATE: {
            const { dateStart, dateEnd } = action.payload;

            return { ...state, dateStart, dateEnd };
        }
        case RECOMMENDATIONS_FILTER_SET_MARK_STATUS:
            return { ...state, markStatus: action.payload };
        case RECOMMENDATIONS_FILTER_SET_MARK_STATUS_TYPE:
            return { ...state, markStatusType: action.payload };
        case RECOMMENDATIONS_FILTER_SORT_TYPE:
            return { ...state, sortType: action.payload };
        case RECOMMENDATIONS_FILTER_CLEAR:
            return { ...initialState };
        default:
            return state;
    }
};

export const selectRecommendationsFilter = (state) => state.recommendationsFilter;
