// eslint-disable-next-line import/no-anonymous-default-export
export default async function (url, init) {
    init = { ...init, "credentials": "include" }

    const response = await fetch(url, { ...init })
    const json = await response.json()

    if (!response.ok) {
        throw new Error(json.message || 'Что-то пошло не так')
    }

    return json
}