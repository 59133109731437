export const UPDATE_USER = 'AUTH/UPDATE_USER';
export const SET_USER = 'AUTH/SET_USER';

export const START_LOADING = 'APP/START_LOADING';
export const FINISH_LOADING = 'APP/FINISH_LOADING';
export const SHOW_ALERT = 'APP/SHOW_ALERT';
export const HIDE_ALERT = 'APP/HIDE_ALERT';
export const SHOW_NOTICE = 'APP/SHOW_NOTICE';
export const HIDE_NOTICE = 'APP/HIDE_NOTICE';

export const SET_QUESTIONNAIRE_FIELD = 'QUESTIONNAIRE/SET_QUESTIONNAIRE_FIELD';
export const SET_QUESTIONNAIRE_FORM = 'QUESTIONNAIRE/SET_QUESTIONNAIRE_FORM';
export const SET_LOCATION = 'QUESTIONNAIRE/SET_LOCATION';
export const SET_CHOSEN_ANSWERS = 'QUESTIONNAIRE/SET_CHOSEN_ANSWERS';
export const SET_FACTORS_IMPORTANCE = 'QUESTIONNAIRE/SET_FACTORS_IMPORTANCE';
export const SET_FACTORS_QUESTIONS = 'QUESTIONNAIRE/SET_FACTORS_QUESTIONS';
export const SET_FILLING_START_TIME = 'QUESTIONNAIRE/SET_FILLING_START_TIME';

export const GET_RESPONDENT = 'RESPONDENT/GET_RESPONDENT';
export const CHANGE_RESPONDENT_SPECIALIST = 'RESPONDENT/CHANGE_RESPONDENT_SPECIALIST';
export const CHANGE_RESPONDENT_MARK = 'RESPONDENT/CHANGE_RESPONDENT_MARK';
export const GET_MORE_RESPONDENTS = 'RESPONDENT/GET_MORE_RESPONDENTS';
export const GET_RESPONDENTS_COUNT = 'RESPONDENT/GET_RESPONDENTS_COUNT';
export const SET_SEARCH_RESPONDENT = 'RESPONDENT/SET_SEARCH_RESPONDENT';
export const SET_RESPONDENTS_FILTERS = 'RESPONDENT/SET_RESPONDENTS_FILTERS';
export const SET_DATES_FILTERS = 'RESPONDENT/SET_DATES_FILTERS';
export const CLEAR_RESPONDENTS_FILTERS = 'RESPONDENT/CLEAR_RESPONDENTS_FILTERS';

export const GET_STATISTIC = 'STATISTIC/GET_STATISTIC';
export const GET_AGE = 'STATISTIC/GET_AGE';
export const GET_LOCATION = 'STATISTIC/GET_LOCATION';
export const GET_RESIDENCE_TYPE = 'STATISTIC/GET_RESIDENCE_TYPE';
export const GET_PROFESSIONAL_AREA = 'STATISTIC/GET_PROFESSIONAL_AREA';
export const GET_EDUCATION = 'STATISTIC/GET_EDUCATION';
export const GET_FAMILY = 'STATISTIC/GET_FAMILY';
export const GET_EMPLOYMENT = 'STATISTIC/GET_EMPLOYMENT';
export const GET_MARRIAGE = 'STATISTIC/GET_MARRIAGE';
export const GET_CHILDREN = 'STATISTIC/GET_CHILDREN';
export const GET_MARRIAGE_TIME = 'STATISTIC/GET_MARRIAGE_TIME';
export const GET_YOUNGEST_CHILD = 'STATISTIC/GET_YOUNGEST_CHILD';
export const GET_WISH_CHILDREN = 'STATISTIC/GET_WISH_CHILDREN';
export const GET_PLAN_CHILDREN = 'STATISTIC/GET_PLAN_CHILDREN';
export const GET_ABORTION = 'STATISTIC/GET_ABORTION';
export const GET_FORCE = 'STATISTIC/GET_FORCE';
export const GET_RELATIVES_HELP = 'STATISTIC/GET_RELATIVES_HELP';
export const GET_CONDITIONS_FOR_CHILDREN = 'STATISTIC/GET_CONDITIONS_FOR_CHILDREN';
export const GET_STATE_SUPPORT = 'STATISTIC/GET_STATE_SUPPORT';
export const GET_PARENTS_FAMILY = 'STATISTIC/GET_PARENTS_FAMILY';
export const GET_SIBLINGS = 'STATISTIC/GET_SIBLINGS';
export const GET_FACTORS = 'STATISTIC/GET_FACTORS';
export const GET_ANSWERS = 'STATISTIC/GET_ANSWERS';

export const SET_DATA_FACTORS = 'DATA/SET_DATA_FACTORS';
export const SET_DATA_FACTORS_FOR_RECOMS = 'DATA/SET_DATA_FACTORS_FOR_RECOMS';
export const SET_DATA_EDITING_QUESTION = 'DATA/SET_DATA_EDITING_QUESTION';
export const SET_DATA_EDITING_QUESTION_ANSWER = 'DATA/SET_DATA_EDITING_QUESTION_ANSWER';
export const SET_EDITING_QUESTION_ANSWER_DELETE = 'DATA/SET_EDITING_QUESTION_ANSWER_DELETE';
export const SET_DATA_EDITING_FACTOR = 'DATA/SET_DATA_EDITING_FACTOR';
export const SET_DATA_EDITING_FACTOR_ANSWER = 'DATA/SET_DATA_EDITING_FACTOR_ANSWER';
export const SET_EDITING_FACTOR_ANSWER_DELETE = 'DATA/SET_EDITING_FACTOR_ANSWER_DELETE';
export const SET_DATA_QUESTIONS = 'DATA/SET_DATA_QUESTIONS';
export const SET_DATA_QUESTIONS_FOR_RECOMS = 'DATA/SET_DATA_QUESTIONS_FOR_RECOMS';
export const SET_DATA_EDITING_RECOMMENDATION = 'DATA/SET_DATA_EDITING_RECOMMENDATION';
export const SET_MARKED_RECOMS = 'DATA/SET_MARKED_RECOMS';
export const SET_DATA_TEXTS = 'DATA/SET_DATA_TEXTS';
export const SET_SUPPORT_TYPE = 'SET_SUPPORT_TYPE';
export const RECOMMENDATIONS_FILTER_SET_REGION = 'RECOMMENDATIONS_FILTER/SET_REGION';
export const RECOMMENDATIONS_FILTER_SET_DATE = 'RECOMMENDATIONS_FILTER/SET_DATE';
export const RECOMMENDATIONS_FILTER_SET_MARK_STATUS = 'RECOMMENDATIONS_FILTER/SET_MARK_STATUS';
export const RECOMMENDATIONS_FILTER_SET_MARK_STATUS_TYPE =
    'RECOMMENDATIONS_FILTER/SET_MARK_STATUS_TYPE';
export const RECOMMENDATIONS_FILTER_SORT_TYPE = 'RECOMMENDATIONS_FILTER/SORT_TYPE';
export const RECOMMENDATIONS_FILTER_CLEAR = 'RECOMMENDATIONS_FILTER/CLEAR';
