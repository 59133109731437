import axios from 'axios';

import { showAlert, finishLoading, startLoading } from 'redux/appReducer';
import {
    GET_ABORTION,
    GET_AGE,
    GET_ANSWERS,
    GET_CHILDREN,
    GET_CONDITIONS_FOR_CHILDREN,
    GET_EDUCATION,
    GET_EMPLOYMENT,
    GET_FACTORS,
    GET_FAMILY,
    GET_FORCE,
    GET_LOCATION,
    GET_MARRIAGE,
    GET_MARRIAGE_TIME,
    GET_PARENTS_FAMILY,
    GET_PLAN_CHILDREN,
    GET_PROFESSIONAL_AREA,
    GET_RELATIVES_HELP,
    GET_RESIDENCE_TYPE,
    GET_SIBLINGS,
    GET_STATE_SUPPORT,
    GET_STATISTIC,
    GET_WISH_CHILDREN,
    GET_YOUNGEST_CHILD
} from './types';

const initialState = {
    statistic: null,
    age: [],
    location: [],
    residenceType: [],
    professionalArea: [],
    education: [],
    family: [],
    employment: [],
    marriage: [],
    children: [],
    marriageTime: [],
    youngestChild: [],
    wishChildren: [],
    planChildren: [],
    abortion: [],
    force: [],
    relativesHelp: [],
    conditionsForChildren: [],
    stateSupport: [],
    parentsFamily: [],
    siblings: [],
    factors: [],
    answers: []
};

export const statisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTIC:
            return { ...state, statistic: action.payload };
        case GET_AGE:
            return { ...state, age: action.payload };
        case GET_LOCATION:
            return { ...state, location: action.payload };
        case GET_RESIDENCE_TYPE:
            return { ...state, residenceType: action.payload };
        case GET_PROFESSIONAL_AREA:
            return { ...state, professionalArea: action.payload };
        case GET_EDUCATION:
            return { ...state, education: action.payload };
        case GET_FAMILY:
            return { ...state, family: action.payload };
        case GET_EMPLOYMENT:
            return { ...state, employment: action.payload };
        case GET_MARRIAGE:
            return { ...state, marriage: action.payload };
        case GET_CHILDREN:
            return { ...state, children: action.payload };
        case GET_MARRIAGE_TIME:
            return { ...state, marriageTime: action.payload };
        case GET_YOUNGEST_CHILD:
            return { ...state, youngestChild: action.payload };
        case GET_WISH_CHILDREN:
            return { ...state, wishChildren: action.payload };
        case GET_PLAN_CHILDREN:
            return { ...state, planChildren: action.payload };
        case GET_ABORTION:
            return { ...state, abortion: action.payload };
        case GET_FORCE:
            return { ...state, force: action.payload };
        case GET_RELATIVES_HELP:
            return { ...state, relativesHelp: action.payload };
        case GET_CONDITIONS_FOR_CHILDREN:
            return { ...state, conditionsForChildren: action.payload };
        case GET_STATE_SUPPORT:
            return { ...state, stateSupport: action.payload };
        case GET_PARENTS_FAMILY:
            return { ...state, parentsFamily: action.payload };
        case GET_SIBLINGS:
            return { ...state, siblings: action.payload };
        case GET_FACTORS:
            return { ...state, factors: action.payload };
        case GET_ANSWERS:
            return { ...state, answers: action.payload };
        default:
            return state;
    }
};

export const fetchStatistic = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic');

        dispatch({ type: GET_STATISTIC, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchAge = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/age');

        dispatch({ type: GET_AGE, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchLocation = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/location');

        dispatch({ type: GET_LOCATION, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchYoungestChild = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/youngest-child');

        dispatch({ type: GET_YOUNGEST_CHILD, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchAbortion = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/abortion');

        dispatch({ type: GET_ABORTION, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchSiblings = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/siblings');

        dispatch({ type: GET_SIBLINGS, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchFactors = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/factors');

        dispatch({ type: GET_FACTORS, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const fetchAnswers = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        const result = await axios.get('statistic/answers');

        dispatch({ type: GET_ANSWERS, payload: result.data });
    } catch (e) {
        const text = e.response?.data?.message || 'Что-то пошло не так';
        dispatch(showAlert({ type: 'error', text }));
    } finally {
        dispatch(finishLoading());
    }
};

export const selectStatistic = (state) => state.statistic;
